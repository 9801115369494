import { GiftVo, SaveLifeControllerService } from '@/__generated__/FrontApi';
import Container from '@/components/Container';
import PrdInformation from '@/components/Product/PrdInformation';
import { Product } from '@/components/Product/types';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper from '@/helpers/GtmHelper';
import { SUPPORT_TERM_ONCE } from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import SessionStorage from '@/services/SessionStorage';
import { PageProps } from 'gatsby';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { A11y, Navigation, Scrollbar, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Scrollbar, A11y, Thumbs]);

const Section = styled.section`
  padding: 96px 0 64px 0;

  &.sec-detail {
    padding-top: 0;
  }

  ${breakpoint(`tablet`)} {
    padding: 48px 0 40px 0;
  }
`;

const DetailFelx = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 -64px;

  ${breakpoint(1120)} {
    margin: 0 -5.71vw;
  }

  ${breakpoint(`tablet`)} {
    margin: 0;
  }
`;

const ColThumb = styled.div`
  padding: 0 64px;
  width: 46.2%;

  ${breakpoint(1120)} {
    padding: 0 5.71vw;
  }

  ${breakpoint(`tablet`)} {
    width: 100%;
    padding: 0;
    padding-bottom: 24px;
  }
`;

const ColInfo = styled.div`
  padding: 0 64px;
  width: 53.8%;
  form {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }

  ${breakpoint(1120)} {
    padding: 0 5.71vw;
  }

  ${breakpoint(`tablet`)} {
    width: 100%;
    padding: 0;
  }
`;

const PrdThumb = styled.div`
  .img-box {
    width: 100%;
    padding-top: 100%;
  }

  ${breakpoint(`tablet`)} {
  }
`;

const ThumList = styled.ul`
  display: flex;
  align-items: stretch;
  margin-top: 32px;
  img {
    width: 110px;
  }

  ${breakpoint(`tablet`)} {
  }
`;

const PrdDetail = styled.div`
  border-top: 1px solid #e5e6e8;
  padding-top: 96px;
  line-height: 1.7;

  // 에디터 상품 상세 이미지 높이값 깨지는 경우를 위한 auto설정
  img {
    height: auto !important;
  }
`;

const IgDetail: FC<PageProps> = ({ location, params: { productCode } }) => {
  const queryParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [giftProduct, setGiftProduct] = useState<Product>();

  const loadGiftProduct = useCallback(async () => {
    try {
      const {
        data,
      } = await SaveLifeControllerService.getSaveLifeListUsingGet();

      const gift = data.filter(
        (product: GiftVo) => product.productCode === productCode,
      )[0];
      setGiftProduct(gift);

      // gtm 처리
      GtmHelper.viewIgItem({
        itemId: gift.productCode,
        itemName: gift.productName,
        itemCategory: SUPPORT_TERM_ONCE,
        itemListName: gift.categoryCodeName,
      });
    } catch (e) {
      console.error(e);
    }
  }, [productCode]);

  useEffect(() => {
    loadGiftProduct();
  }, [loadGiftProduct]);

  // solicitcode 넘어오면 세션스토리지에 저장
  useEffect(() => {
    const solicitCode = queryParams.get(`solicitcode`);
    if (solicitCode) {
      SessionStorage.setItem(`solicitcode`, solicitCode);
    }
  }, [queryParams]);

  if (!giftProduct) {
    return null;
  }

  return (
    <LayoutWithTitle
      location={location}
      title="생명을 구하는 선물"
      description="for every child, inspired gifts"
    >
      <Section className="sec-prd">
        <Container>
          <DetailFelx>
            <ColThumb>
              <PrdThumb>
                <Swiper thumbs={{ swiper: thumbsSwiper }} slidesPerView="auto">
                  <SwiperSlide>
                    <div
                      className="big-img img-box"
                      css={`
                        background-image: url(${giftProduct.imgProductAttImgNo1_420});
                      `}
                    >
                      <span className="a11y">{giftProduct.productName}</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="big-img img-box"
                      css={`
                        background-image: url(${giftProduct.imgProductAttImgNo2_420});
                      `}
                    >
                      <span className="a11y">{giftProduct.productName}</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="big-img img-box"
                      css={`
                        background-image: url(${giftProduct.imgProductAttImgNo3_420});
                      `}
                    >
                      <span className="a11y">{giftProduct.productName}</span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="big-img img-box"
                      css={`
                        background-image: url(${giftProduct.imgProductAttImgNo4_420});
                      `}
                    >
                      <span className="a11y">{giftProduct.productName}</span>
                    </div>
                  </SwiperSlide>
                </Swiper>

                <ThumList>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    slidesPerView={4}
                    navigation={false}
                    scrollbar={{ draggable: false }}
                    spaceBetween={30}
                  >
                    <SwiperSlide>
                      <img src={giftProduct.imgProductAttImgNo1_290} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={giftProduct.imgProductAttImgNo2_290} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={giftProduct.imgProductAttImgNo3_290} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={giftProduct.imgProductAttImgNo4_290} alt="" />
                    </SwiperSlide>
                  </Swiper>
                </ThumList>
              </PrdThumb>
            </ColThumb>
            <ColInfo>
              <PrdInformation detail={giftProduct} />
            </ColInfo>
          </DetailFelx>
        </Container>
      </Section>

      <Section className="sec-detail">
        <Container>
          <PrdDetail
            dangerouslySetInnerHTML={{ __html: giftProduct.productExplanation }}
          />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default IgDetail;
